// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



global.$ = require("jquery");

require("jquery-ui");

import 'popper.js';
global.Popper = require("popper.js");
import "../stylesheets/application"
import 'quill/dist/quill.snow.css';
import Quill from 'quill/dist/quill.js';

export default Quill;

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import _ from "underscore";
import "channels";

import "bootstrap";


Rails.start()
//Turbolinks.start()
ActiveStorage.start()

$(document).ready( function() {

  $(".history_back").on("click", function(e){
      e.preventDefault();
      window.history.back();
  });
  if($('#editor_container').length > 0){
    var quill = new Quill('#editor_container', { theme: 'snow',
      modules: {
        'toolbar': [ [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['image', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]
               ] }
    });


    var save_it = _.throttle(function(){

      var out = {};

      out['document'] = document.querySelector(".ql-editor").innerHTML;
      $.ajax({
          type: 'PUT',
          data: {list: out},
          success: function(result) {
              // Do something with the result
          }
      });
    }, 1500 );

    quill.on('text-change', function(delta, source) {

      save_it();
      return(true);
    });

  }
})
